import { lazy } from 'react';
import MinimalLayout from 'layout/MinimalLayout/index';

// project import
import Loadable from 'components/Loadable';
// import Home from 'pages/home/Home';

// render - login
const ScopePath = Loadable(lazy(() => import('components/Scope/index')));

const ScopeRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'scopes',
      element: <ScopePath />
    }
  ]
};

export default ScopeRoutes;
