// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { ToastContainer } from 'react-toastify';
import { AppsContext } from 'AppsContext';
import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const [appsContext, setAppsContext] = useState(undefined);
  // const stripePromise = loadStripe(
  //   'pk_live_51NkGRuJUdhZkPc8orV48QPKN0ylg5AoiMnRAv1maRiBkSSfrH1PdevJt3PeNsHfO7YI5aYNts6OB7dcbw5gAxdFd00fmRvwB5M'
  // );
  const stripePromise = loadStripe(process.env.REACT_APP_PK_STRIPE);

  return (
    <AppsContext.Provider value={{ appsContext, setAppsContext }}>
      <ThemeCustomization>
        <ScrollTop>
          <ToastContainer />
          <Elements stripe={stripePromise}>
            <Routes />
          </Elements>
        </ScrollTop>
      </ThemeCustomization>
    </AppsContext.Provider>
  );
};

export default App;
