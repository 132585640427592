import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';


// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const ForgetPassword = Loadable(lazy(()=> import('pages/authentication/Forget')))
const CheckEmail = Loadable(lazy(()=> import('pages/authentication/CheckEmail')))
const Resetpassword = Loadable(lazy(()=> import('pages/authentication/Reset')))
const ResetpasswordSuccess = Loadable(lazy(()=> import('pages/authentication/ResetSuccess')))




// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <AuthLogin />
    },
    {
      path: 'register',
      element: <AuthRegister />
    },
    {
      path: 'forgetpassword',
      element: <ForgetPassword/>
    },
    {
      path: 'checkemail',
      element: <CheckEmail/>
    },
    {
      path: 'resetpassword',
      element: <Resetpassword/>
    },
    {
      path: 'resetsuccess',
      element: <ResetpasswordSuccess/>
    }
  ]
};

export default LoginRoutes;
