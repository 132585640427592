import { useRoutes } from 'react-router-dom';
import LoginRoutes from './LoginRoutes';
import HomeRoutes from './HomeRoutes';
import MapRoutes from './MapRoutes';
import ScopeRoutes from './ScopeRoutes';
import UpdateRoutes from './UpdateRoutes';
import { useContext } from 'react';
import { AppsContext } from 'AppsContext';
import React, { useState, useEffect } from 'react';
import MapService from 'Services/MapService';
import CheckoutRoutes from './CheckoutRoute';
import CartRoutes from './CartRoute';
import ProductOfferRoutes from './ProductOfferRoute';
import ProductRoutes from './ProductRoute';

export default function ThemeRoutes() {
  const appsContext = useContext(AppsContext);
  const [dynamicRoutes, setDynamicRoutes] = useState([]);

  const importComponent = async (path) => {
    try {
      const module = await import(`components/MapData/${path}`);
      return module.default;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  const routesPromises = appsContext.appsContext?.map(async (config) => {
    const { root } = config;
    const path = `${root}Data`;
    const component = await importComponent(path);

    if (!component || component === undefined) {
      return null;
    }

    return {
      path: `/${root}/:geoId`,
      element: React.createElement(component)
    };
  });

  useEffect(() => {
    const fetchDynamicRoutes = async () => {
      if (!appsContext.appsContext) {
        MapService.getApplications()
          .then(async (res) => {
            appsContext.setAppsContext(res);
            const resolvedRoutes = await Promise.all(routesPromises);
            setDynamicRoutes(resolvedRoutes.filter((route) => route !== null));
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const resolvedRoutes = await Promise.all(routesPromises);
        setDynamicRoutes(resolvedRoutes.filter((route) => route !== null));
      }
    };

    fetchDynamicRoutes();
  }, [appsContext]);

  return useRoutes([
    LoginRoutes,
    HomeRoutes,
    UpdateRoutes,
    MapRoutes,
    ...dynamicRoutes,
    ScopeRoutes,
    CheckoutRoutes,
    CartRoutes,
    ProductOfferRoutes,
    ProductRoutes
  ]);
}
