import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
// import Home from 'pages/home/Home';

// render - login
const CartPath = Loadable(lazy(() => import('components/cart/index')));
// const LoginPath = Loadable(lazy(() => import('pages/authentication/Login')));

// ==============================|| AUTH ROUTING ||============================== //

const CartRoutes = {
  path: '/cart',
  element: <CartPath />
};

export default CartRoutes;
