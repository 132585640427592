import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
// import Home from 'pages/home/Home';

// render - login
const CheckoutPath = Loadable(lazy(() => import('components/checkout/index')));
// const LoginPath = Loadable(lazy(() => import('pages/authentication/Login')));

// ==============================|| AUTH ROUTING ||============================== //

const CheckoutRoutes = {
  path: '/checkout/success',
  element: <CheckoutPath />
};

export default CheckoutRoutes;
