import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
// import Home from 'pages/home/Home';

// render - login
const HomePath = Loadable(lazy(() => import('pages/home/Home')));
// const LoginPath = Loadable(lazy(() => import('pages/authentication/Login')));

// ==============================|| AUTH ROUTING ||============================== //

const HomeRoutes = {
  path: '/home',
  element: <HomePath />
};

export default HomeRoutes;
