import { lazy } from 'react';
import MinimalLayout from 'layout/MinimalLayout/index';

// project import
import Loadable from 'components/Loadable';
// import Home from 'pages/home/Home';

// render - login
const MapPath = Loadable(lazy(() => import('components/Map/index')));
const PreviewData = Loadable(lazy(() => import('components/Map/Component/PrevData/PreviewData')));
// const LoginPath = Loadable(lazy(() => import('pages/authentication/Login')));

// ==============================|| AUTH ROUTING ||============================== //

const MapRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'map',
      element: <MapPath />
    },
    {
      path: 'map/data-apps/:geoId',
      element: <PreviewData />
    }
  ]
};


export default MapRoutes;
