import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
// import Home from 'pages/home/Home';

// render - login
const ProductOfferPath = Loadable(lazy(() => import('components/productOffer/index')));
// const LoginPath = Loadable(lazy(() => import('pages/authentication/Login')));

// ==============================|| AUTH ROUTING ||============================== //

const ProductOfferRoutes = {
  path: '/product-offers',
  element: <ProductOfferPath />
};

export default ProductOfferRoutes;
