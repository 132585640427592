import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
// import Home from 'pages/home/Home';

// render - login
const UpdatePath = Loadable(lazy(() => import('pages/home/Update')));
// const LoginPath = Loadable(lazy(() => import('pages/authentication/Login')));

// ==============================|| AUTH ROUTING ||============================== //

const UpdateRoutes = {
  path: '/Update',
  element: <UpdatePath />
};

export default UpdateRoutes;