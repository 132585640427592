// eslint-disable-file no-unused-vars
import axios from 'axios';
import Cookies from 'js-cookie';
import { KEYS } from './Constants';

export const axiosObj = axios.create();

axiosObj.interceptors.request.use((config) => {
  const token = `Bearer ${Cookies.get('access_token')}`;
  if ('headers' in config && config.headers && 'Authorization' in config.headers) {
    config.headers.Authorization = token;
  } else {
    config.headers = { Authorization: token };
  }
  return config;
});

export const makeRequest = (url, method, headers, body, params) => {
  // return new Promise(async (resolve, reject) => {
  return new Promise((resolve, reject) => {
    axiosObj({
      url: url,
      method: method,
      headers: headers,
      data: body,
      params
    })
      .then((response) => {
        resolve(response);
        if (url?.includes('fetch-session-cookies')) {
          if (!response.data.access_token || !response.data.refresh_token) {
            Cookies.remove(KEYS.ACCESS_TOKEN_COOKIE);
            Cookies.remove(KEYS.REFRESH_TOKEN_COOKIE);
          } else {
            Cookies.set(KEYS.ACCESS_TOKEN_COOKIE, response.data.access_token);
            Cookies.set(KEYS.REFRESH_TOKEN_COOKIE, response.data.refresh_token);
          }
        }
      })
      .catch((err) => {
        // Handle error here.
        reject(err);
      });
  });
};
